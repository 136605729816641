import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "../api";
import {Tabs} from "antd";
import Contracts from "./contract/Contracts";
import BackTitle from "../common/BackTitle";
import { UserContext } from "../App";

export default function CustomerDetails(){
    const {id} = useParams()
    const [customer, setCustomer] = useState({})
    const [loading, setLoading] = useState(false)
    const user = useContext(UserContext) 

    useEffect(() => {
        setLoading(true)
       axiosInstance.get(`customer/${id}`)
           .then(({data})=> setCustomer(data))
           .finally(() => setLoading(false))
    }, []);

    const items = [
        {
            key: '1',
            label: 'Contratti',
            permission: 'contract.read',
            children: <Contracts/>
        }
    ].filter(x => user.permissions?.includes(x.permission))

    return <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
        <BackTitle padding={0} title={`Dettagli cliente: ${customer.name}`}/>
        <Tabs items={items} />
    </div>
}
