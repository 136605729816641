
import {useContext} from "react";
import { UserContext } from "../App";


export default function PermissionRequired({name,requireIf,children}){
    requireIf = requireIf !== undefined ? requireIf : true
    const user = useContext(UserContext)
    if(requireIf)
        return (user.permissions?.includes(name) ?? false) ? children : ""
    else
        return children
}
