import { useEffect, useRef, useState } from "react";
import H from "@here/maps-api-for-javascript";
import ReactDOMServer from "react-dom/server";
import { Card, message, Space, Tag, Typography } from "antd";
import { act } from "react";
import { EditOutlined, ExportOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const getConstraint = (record) => {
    if (record.authorizationState === "WAITING")
        return <Tag color={"red"}>Attesa autorizzazione</Tag>;

    if (record.municipalityOrdinanceState === "WAITING")
        return <Tag color={"red"}>Attesa ordinanza</Tag>;

    if (record.municipalityOrdinanceState === "OK")
        return (
            <Tag color={"volcano"}>
                Ordinanza dal {dayjs(record.municipalityOrdinanceStartDate).format("DD/MM")} al{" "}
                {dayjs(record.municipalityOrdinanceEndDate).format("DD/MM")}
            </Tag>
        );

    return null;
};

function getMarkerIcon(x) {
    const radius = 30;
    const percentage = Math.floor(
        (x.activities
            .map((y) => Math.min(1, y.doneQuantity / y.toDoQuantity))
            .reduce((acc, curr) => acc + curr, 0) *
            100) /
            x.activities.length,
    );
    const showWarning = x.activities.some(getConstraint);

    const width = radius * 2;
    const height = radius * 2;

    const uniqueTypes = [...new Set(x.activities.map((x) => JSON.stringify(x.type)))].map(
        JSON.parse,
    );
    const colWidth = width / uniqueTypes.length;
    const rectsSvg = uniqueTypes
        .map(
            (type, i) =>
                `<rect width="${colWidth}" height="${height}" x="${i * colWidth}" y="${0}" fill="${type.color}" opacity="0.85" />`,
        )
        .join("");
    //stroke="white" stroke-width="2"  opacity="0.8"
    const svgCircle = `<svg width="${width}" height="${width}" version="1.2" xmlns="http://www.w3.org/2000/svg">
<defs>
<filter x="0" y="0" width="1" height="1" id="solid">
      <feFlood flood-color="black" result="bg" flood-opacity="0.5"/>
      <feMerge>
        <feMergeNode in="bg"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>

    <clipPath id="cut-bottom">
      <circle cx="${radius}" cy="${radius}" r="${radius}" stroke="white" stroke-width="2"  opacity="0.8" />
    </clipPath>
  </defs>
<g id="marker" clip-path="url(#cut-bottom)">
${rectsSvg}
<text  font-family="Arial, Helvetica, sans-serif" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">${percentage}%</text>
${
    showWarning
        ? `<circle cx="${radius}" cy="${radius}" r="${radius}" fill-opacity="0" stroke="white" stroke-width="2"/>
<svg x="19"  y="62%" width="60" height="60" fill="#ffd500" viewBox="0 0 100 100">
<path d="M30.555 25.219l-12.519-21.436c-1.044-1.044-2.738-1.044-3.782 0l-12.52 21.436c-1.044 1.043-1.044 2.736 0 3.781h28.82c1.046-1.045 1.046-2.738 0.001-3.781zM14.992 11.478c0-0.829 0.672-1.5 1.5-1.5s1.5 0.671 1.5 1.5v7c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v-7zM16.501 24.986c-0.828 0-1.5-0.67-1.5-1.5 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.83-0.672 1.5-1.5 1.5z"></path>
</svg>`
        : ""
}
</g>

</svg>
`;
    return new H.map.Icon(svgCircle, { anchor: { x: radius, y: radius } });
}

const renderInfoBubble = (el) =>
    ReactDOMServer.renderToString(
        <>
            <a target="_blank" rel="noopener noreferrer" href={`orders/${el.order.id}`}>
                {el.order.title} <ExportOutlined/>
            </a>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 0 }}>
                <span>{el.order.customer}</span>
                <span style={{ fontSize: "0.8em" }}>{el.order.fullAddress}</span>
                {el.activities.map((activity) => (
                    <div
                        key={activity.id}
                        style={{ display: "flex", flexDirection: "column", rowGap: 0 }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                width: "100%",
                                height: 1,
                                marginTop: 10,
                                marginBottom: 10,
                                background: "gray",
                            }}
                        />
                        <span style={{ fontWeight: "bold", color: activity.type.color }}>
                            {activity.type.name}
                        </span>
                        {activity.notes && <span>Note: {activity.notes}</span>}
                        {getConstraint(activity) && (
                            <span style={{ color: "#fa541c", fontWeight: "bold" }}>
                                <u>Vincoli: {getConstraint(activity)}</u>
                            </span>
                        )}
                        <span>
                            Stato avanzamento: {activity.doneQuantity}/{activity.toDoQuantity}{" "}
                            {activity.type.measureUnit} ({" "}
                            {Math.floor((activity.doneQuantity * 100) / activity.toDoQuantity)}% ){" "}
                        </span>
                    </div>
                ))}
            </div>
        </>,
    );

export default function ({ activities, style }) {
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);
    const [ui, setUi] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const apiKey = process.env.REACT_APP_API_KEY;

    useEffect(() => {
        const tmp = Object.values(
            activities.reduce(
                (acc, x) => ({
                    ...acc,
                    [x.order.id]: {
                        order: x.order,
                        activities: [...(acc[x.order.id]?.activities ?? []), x],
                    },
                }),
                {},
            ),
        );
        setDataSource(tmp);
    }, [activities]);

    useEffect(
        () => {
            // Check if the map object has already been created
            if (!map.current) {
                // Create a platform object with the API key
                platform.current = new H.service.Platform({ apiKey });

                const defaultLayers = platform.current.createDefaultLayers({
                    lg: "it-IT",
                    queryParams: {
                        style: "lite.day",
                        size: 800,
                    },
                });
                // Create a new map instance with the Tile layer, center and zoom level
                const newMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                    pixelRatio: window.devicePixelRatio || 1,
                    zoom: 16,
                    padding: { top: 100, left: 100, bottom: 100, right: 100 },
                });

                const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap));
                setUi(H.ui.UI.createDefault(newMap, defaultLayers, "it-IT"));
                // Set the map object to the reference
                map.current = newMap;
            } else {
                if (dataSource && dataSource.length > 0) {
                    map.current.removeObjects(map.current.getObjects());
                    const group = new H.map.Group();
                    group.addObjects(
                        dataSource.map((x) => {
                            const marker = new H.map.Marker(
                                { lat: x.order.lat, lng: x.order.lng },
                                { icon: getMarkerIcon(x) },
                            );
                            marker.setData(x);
                            marker.addEventListener(
                                "tap",
                                (event) => {
                                    const bubble = new H.ui.InfoBubble(
                                        { lat: x.order.lat, lng: x.order.lng },
                                        {
                                            content: renderInfoBubble(event.target.getData()),
                                        },
                                    );
                                    ui.addBubble(bubble);
                                },
                                false,
                            );
                            return marker;
                        }),
                    );
                    map.current.addObject(group);
                    map.current.getViewModel().setLookAtData({
                        bounds: group.getBoundingBox(),
                    });
                }
            }
        },
        // Dependencies array
        [dataSource],
    );

    const legend = Array.from(
        new Set(dataSource.flatMap((x) => x.activities).map((x) => JSON.stringify(x.type))),
    )
        .map(JSON.parse)
        .map((x) => ({ label: x.name, color: x.color }));
    return (
        <div>
            {
                <div style={{ position: "absolute", zIndex: 2, right: 24 }}>
                    <Card
                        style={{ opacity: 0.97, margin: 20 }}
                        title="Legenda"
                        bordered={false}
                        bodyStyle={{ padding: 20 }}
                    >
                        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                            {legend.map((x) => (
                                <li
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        lineHeight: "25px",
                                    }}
                                >
                                    <span
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginRight: 10,
                                            borderRadius: "50%",
                                            display: "inline-block",
                                            background: x.color,
                                        }}
                                    />
                                    {x.label}
                                </li>
                            ))}
                        </ul>
                    </Card>
                </div>
            }
            <div style={{ width: "100%", height: "800px", ...style }} ref={mapRef} />
        </div>
    );
}
