import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Space} from "antd";
import {filterOption} from "../util";
import React, {useEffect, useState} from "react";
import axiosInstance from "../api";
import {useForm} from "antd/lib/form/Form";
import {useSearchParams} from "react-router-dom";


export default function OrderSearchFilters({style}) {
    const [customers, setCustomers] = useState(false)
    const [loadingCities, setLoadingCities] = useState(false)
    const [timer, setTimer] = useState()
    const [cities, setCities] = useState([])
    const [form] = useForm();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        axiosInstance.get("customer/compact")
            .then(({data}) => setCustomers(data.map(x => ({label: x.name, value: x.id}))))
    }, []);

    const handleSearchCityChange = text => {
        if (timer)
            clearTimeout(timer)
        if (text.length > 2) {
            setCities([])
            setLoadingCities(true)
            const timeout = setTimeout(() => {
                axiosInstance.get(`city?q=${text}`)
                    .then(({data}) => setCities(data))
                    .finally(() => setLoadingCities(false))
            }, 100)
            setTimer(timeout)
        }
    }

    const onFinish = form => {
        [...searchParams.keys()].forEach(x => searchParams.delete(x))

        Object.entries(form)
            .filter(([k, v]) => !!v)
            .forEach(([k, v]) => searchParams.set(k, v))
        setSearchParams(searchParams)
    }

    useEffect(() => {
        const data = [...searchParams.keys()].reduce((acc, k) => ({...acc, [k]: searchParams.get(k)}), {})

        form.setFieldsValue(data);
        ["customerId"].filter(x => !!form.getFieldValue(x))
            .forEach(x => form.setFieldValue(x, Number(form.getFieldValue(x))))
    }, [searchParams]);

    return <div {...{style}}>
        <Form onFinish={onFinish} form={form} layout={"vertical"}>
            <Row gutter={[10, 10]}>
                <Col flex={"1200px"}>
                    <Space wrap>
                        <Form.Item label={"Stato commessa"} style={{margin: 0}} name={"state"} initialValue={"OPEN"}>
                            <Select style={{width: 150}} placeholder={"Stato"}
                                    options={[
                                        {label: 'Tutti', value: 'ALL'},
                                        {label: 'Aperta', value: 'OPEN'},
                                        {label: 'Chiusa', value: 'CLOSED'}
                                    ]}
                            />
                        </Form.Item>
                        <Form.Item label={"Parola chiave"} style={{margin: 0}} name={"q"}>
                            <Input style={{width: 150}} placeholder={"Parola chiave"} allowClear/>
                        </Form.Item>
                        <Form.Item label={"Cliente"} style={{margin: 0}} name={"customerId"}>
                            <Select allowClear placeholder={"Cliente"} style={{width: 150}} filterOption={filterOption}
                                    options={customers} showSearch/>
                        </Form.Item>
                        <Form.Item label={"Città"} style={{margin: 0}} name={"cityId"}>
                            <Select
                                placeholder={'Città'}
                                allowClear
                                style={{width: 150}}
                                showSearch
                                onSearch={handleSearchCityChange}
                                filterOption={false}
                                notFoundContent={loadingCities ? "Caricamento in corso..." : "Nessun elemento"}
                                options={cities.map(x => ({label: x.name, value: x.id}))}/>
                        </Form.Item>
                        <Form.Item label={"Altri filtri"} style={{margin: 0}} name={"assigned"} valuePropName="checked">
                            <Checkbox>Fogli assegnati a me</Checkbox>
                        </Form.Item>
                    </Space>
                </Col>
                <Col flex={"auto"}>
                    <Space wrap style={{width: '100%', justifyContent: "end", alignContent: "end", height: '100%'}}>
                        <Button onClick={() => {
                            form.resetFields();
                            onFinish({})
                        }}>Reset filtri</Button>
                        <Button htmlType={"submit"} type={"primary"}>Applica filtri</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </div>
}
