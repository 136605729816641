import React, { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Button, Col, Row, Space, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { PlusOutlined } from "@ant-design/icons";
import { formatDateTime } from "../util";
import PermissionRequired from "../common/PermissionRequired";

const columns = [
    {
        key: "name",
        dataIndex: "name",
        title: "Nome",
        width: 150,
        ellipsis: true,
        render: (name, record) => <Link to={`${record.id}`}>{name}</Link>,
    },
    {
        key: "createdBy",
        dataIndex: "createdBy",
        width: 150,
        ellipsis: true,
        title: "Creato da",
        render: (x) => x.firstName + " " + x.lastName,
    },
    {
        key: "creationDate",
        dataIndex: "creationDate",
        width: 150,
        ellipsis: true,
        title: "Data creazione",
        render: formatDateTime,
    },
    {
        key: "updatedBy",
        dataIndex: "updatedBy",
        title: "Modificato da",
        width: 150,
        ellipsis: true,
        render: (x) => x.firstName + " " + x.lastName,
    },
    {
        key: "updateDate",
        dataIndex: "updateDate",
        title: "Data modifica",
        width: 150,
        ellipsis: true,
        render: formatDateTime,
    },
];

export default function Customers() {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axiosInstance
            .get("customer")
            .then(({ data }) => setCustomers(data))
            .finally(() => setLoading(false));
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Row>
                <Col span={12}>
                    <BackTitle backEnabled={false} padding={0} title={"Clienti"} />
                </Col>
                <Col span={12}>
                    <PermissionRequired name={"customer.create"}>
                        <Space
                            style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "end",
                            }}
                        >
                            <Button
                                icon={<PlusOutlined />}
                                type={"primary"}
                                onClick={() => navigate("new")}
                            >
                                Nuovo
                            </Button>
                        </Space>
                    </PermissionRequired>
                </Col>
            </Row>
            <Table
                style={{ marginTop: 10 }}
                size={"small"}
                scroll={{ x: columns.reduce((acc, x) => acc + x, 0) }}
                loading={loading}
                columns={columns}
                dataSource={customers.map((x) => ({ ...x, key: x.id, x }))}
            />
        </div>
    );
}
