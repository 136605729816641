import { Form, InputNumber, Modal, Progress, Spin } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../api";

export default function ({ activityId, onUpdateFinished, onCancel }) {
    const [activity, setActivity] = useState({});
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const doneQuantityWatch = Form.useWatch("doneQuantity", form);
    const toDoQuantityWatch = Form.useWatch("toDoQuantity", form);

    useEffect(() => {
        if (activityId) {
            axiosInstance
                .get(`activity/${activityId}`)
                .then(({ data }) => form.setFieldsValue(data))
                .finally(() => setLoading(false));
        }
    }, [activityId]);

    const onFormFinish = (data) => {
        setLoading(true);
        axiosInstance
            .put(`activity/${activityId}/quantity`, data)
            .then(() => onUpdateFinished(data))
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={!!activityId}
            onCancel={onCancel}
            title="Aggiornato stato di avanzamento"
            okButtonProps={{
                htmlType: "submit",
                form: "updateDoneWorkForm",
            }}
        >
            <Spin spinning={loading}>
                <div
                    style={{ width: "100%", textAlign: "center", marginTop: 50, marginBottom: 50 }}
                >
                    <Progress
                        percent={Math.floor((doneQuantityWatch * 100) / toDoQuantityWatch)}
                        type="dashboard"
                    />
                </div>
                <Form layout="inline" id="updateDoneWorkForm" onFinish={onFormFinish} form={form}>
                    <Form.Item label="Quantità eseguita" name="doneQuantity">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Quantità totale" name="toDoQuantity">
                        <InputNumber />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}
