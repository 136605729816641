import { Button, DatePicker, Form, Select } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import dayjs from 'dayjs'
import { DownloadOutlined } from "@ant-design/icons";
import { downloadFile } from "../util";
import BackTitle from "../common/BackTitle";
const { RangePicker } = DatePicker;



export default function Functions() {
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        axiosInstance.get('customer/compact')
            .then(({ data }) => setCustomers(data))
    }, []);

    const onFinish = (form) => {
        const startDate = dayjs(form.range[0]).format("YYYY-MM-DD")
        const endDate = dayjs(form.range[1]).format("YYYY-MM-DD")
        const params = {
            unpaidOnly: false,
            customerId: form.customer,
            startDate,
            excel: true,
            endDate
        };
        setLoading(true)
        downloadFile('accountingItem', 'get', null, { params })
            .finally(() => setLoading(false))
        //axiosInstance.get('accountingItem', {params})
        //    .then(({ data }) => console.log(data.data))
        //    .finally(() => setLoading(false))
    }
    return <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
        <BackTitle backEnabled={false} title="Report produzione" padding={0} />
        <Form onFinish={onFinish}>
            <Form.Item label={"Cliente"} name={"customer"}>
                <Select showSearch filterOption={filterOption}
                    placeholder="Seleziona il cliente"
                    options={customers.map(x => ({ label: x.name, value: x.id }))} />
            </Form.Item>
            <Form.Item label={"Intervallo date"} name={"range"} rules={[requiredField]}>
                <RangePicker format="DD/MM/YYYY" />
            </Form.Item>
            <Form.Item style={{ textAlign: "end" }}>
                <Button htmlType="submit" type="primary"
                    icon={<DownloadOutlined />} loading={loading}>Scarica</Button>
            </Form.Item>
        </Form>
    </div>
}
