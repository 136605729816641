import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { requiredField } from "../util";
import { useNavigate, useParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";

export default function EditOrder() {
    const { orderId } = useParams();

    const [multipliers, setMultipliers] = useState([]);

    const [addresses, setAddresses] = useState([]);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [timer, setTimer] = useState();
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(false);
    const navigate = useNavigate();
    const [contract, setContract] = useState({});
    const [form] = useForm();

    useEffect(() => {
        setInitLoading(true);
        axiosInstance
            .get(`order/${orderId}`)
            .then(({ data }) => {
                form.setFieldsValue({
                    ...data,
                    multiplier: data.multiplier?.id,
                    address: data.addressId ?? null,
                });
                return data;
            })
            .then((data) => {
                if (data.addressId) {
                    axios
                        .get("https://lookup.search.hereapi.com/v1/lookup", {
                            params: { id: data.addressId, apiKey: process.env.REACT_APP_API_KEY },
                        })
                        .then(({ data }) => setAddresses([data]));
                }
                setContract(data.contract);
                return axiosInstance.get(
                    `customer/${data.contract.customer.id}/contract/${data.contract.id}/multiplier/compact`,
                );
            })
            .then(({ data }) => setMultipliers(data))
            .finally(() => setInitLoading(false));
    }, []);

    const handleSearchAddressChange = (text) => {
        if (timer) clearTimeout(timer);
        if (text.length > 3) {
            setLoadingAddresses(true);
            const timeout = setTimeout(() => {
                axios
                    .get("https://geocode.search.hereapi.com/v1/geocode", {
                        params: {
                            q: text,
                            apiKey: process.env.REACT_APP_API_KEY,
                            in: "countryCode:ITA",
                        },
                    })
                    .then(({ data }) => {
                        setAddresses(data.items);
                    })
                    .finally(() => setLoadingAddresses(false));
            }, 200);
            setTimer(timeout);
        }
    };

    const onFinish = (form) => {
        setLoading(true);

        const address = addresses.filter((x) => x.id === form.address)[0];
        form.address = {
            ...address.address,
            lat: address.position.lat,
            lng: address.position.lng,
            id: address.id
        };
        axiosInstance
            .put(`order/${orderId}`, form)
            .then(() => navigate(-1))
            .then(() => {
                message.success("Commessa creata con successo");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={initLoading}>
            <BackTitle padding={0} title={"Modifica commessa"} />
            <Form onFinish={onFinish} layout={"vertical"} style={{ marginTop: 16 }} form={form}>
                <Form.Item label={"Titolo"} name={"title"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                {contract?.requireMultiplier && (
                    <Form.Item
                        label={"Coefficiente valorizzazione"}
                        name={"multiplier"}
                        rules={[requiredField]}
                    >
                        <Select
                            options={multipliers.map((x) => ({ label: x.name, value: x.id }))}
                        />
                    </Form.Item>
                )}
                <Form.Item label={"Indirizzo"} rules={[requiredField]} name={"address"}>
                    <Select
                        showSearch
                        onSearch={handleSearchAddressChange}
                        filterOption={false}
                        style={{ width: 500 }}
                        notFoundContent={
                            loadingAddresses ? "Caricamento in corso..." : "Nessun elemento"
                        }
                        options={addresses.map((x) => ({
                            label: x.address.label,
                            value: x.id,
                        }))}
                    />
                </Form.Item>
                <Form.Item style={{ textAlign: "end" }}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>
                        Salva
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
