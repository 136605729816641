import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Button, Form, message, Modal, Table } from "antd";
import { DownloadOutlined, FileExcelOutlined, UploadOutlined } from "@ant-design/icons";
import { exportCsv } from "../../util";
import Papa from "papaparse";
import axiosInstance from "../../api";
import Toolbar from "../../common/Toolbar";
import Dragger from "antd/es/upload/Dragger";
import { UserContext } from "../../App";
import PermissionRequired from "../../common/PermissionRequired";

const ImportListingModal = ({ contract, open, onFinish, onCancel }) => {
    const [loading, setLoading] = useState(false);

    const downloadTemplate = () => {
        /*axiosInstance
            .get(
                `/customer/${contract.customer.id}/contract/${contract.id}/listingElementMissingFromContract`,
            )
            .then(({ data }) => {
                const l = data.map((x) => ({
                    ID: x.id,
                    Voce: x.refId,
                    "Prezzo/Punti base": x.basePrice,
                    "Applica coefficiente": x.applyMultiplier,
                    "Sconto (%)": (x.discount ?? 0) * 100,
                }));
                exportCsv(l, `listino_contratto-${contract.id}`);
            });*/
        axiosInstance
            .get(`contractListingElement`, { params: { contract: contract.id } })
            .then(({ data }) => {
                const l = data.map((x) => ({
                    ID: x.id,
                    Categoria: x.listingElement.category.name,
                    Voce: x.listingElement.refId,
                    Descrizione: x.listingElement.description,
                    "Prezzo/Punti base": x.basePrice,
                    "Applica coefficiente": x.applyMultiplier ? 1 : 0,
                    "Sconto (%)": (x.discount ?? 0) * 100,
                }));
                exportCsv(l, `listino_contratto-${contract.id}`);
            });
    };

    const onFormFinish = (form) => {
        setLoading(true);
        Papa.parse(form.file.file, {
            skipEmptyLines: true,
            complete: ({ data }) => {
                const toSend = data.slice(1).map((x) => ({
                    //contract: contract.id,
                    //listingElement: x[0],
                    //listing: contract.listing.id,
                    id: x[0],
                    basePrice: Number(x[4]),
                    applyMultiplier: Number(x[5]) === 1,
                    discount: Number(x[6]) / 100,
                }));
                axiosInstance
                    .put(`/contractListingElement`, toSend)
                    .then(() => {
                        message.success("Elenco aggiornato correttamente");
                    })
                    .then(onFinish)
                    .finally(() => setLoading(false));
            },
        });
    };

    return (
        <Modal
            open={open}
            title="Importa da listino"
            onCancel={onCancel}
            okButtonProps={{ htmlType: "submit", form: "importFromListing" }}
        >
            <p>
                Questa procedura permette di modificare o importare gli sconti in modo massivo.
                Scaricare il modello, modificare <b>soltanto</b> il campo sconto percentuale (0-100)
                e caricare il modello salvato.
            </p>
            <div style={{ marginTop: 30 }}>
                Modello:{" "}
                <Button icon={<DownloadOutlined />} onClick={downloadTemplate}>
                    Scarica modello
                </Button>
            </div>
            <Form onFinish={onFormFinish} style={{ marginTop: 20 }} id="importFromListing">
                <Form.Item label={"File"} name="file" valuePropName={"file"}>
                    <Dragger maxCount={1} beforeUpload={() => false}>
                        <p>Trascina qui il modello modificato</p>
                        <Button icon={<UploadOutlined />}>Seleziona file</Button>
                    </Dragger>
                </Form.Item>
                <Form.Item style={{ width: "100%", textAlign: "end" }}>
                    <Button htmlType={"submit"} type={"primary"} loading={loading}>
                        Importa
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default function ContractListingElements({ contract }) {
    const [loading, setLoading] = useState(false);
    const [listingElements, setListingElements] = useState([]);
    const navigate = useNavigate();
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const user = useContext(UserContext);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 100,
            ellipsis: true,
            key: "id",
        },
        {
            key: "refId",
            title: "Voce",
            dataIndex: ["listingElement", "refId"],
            width: 100,
            ellipsis: true,
        },
        {
            title: "Descrizione",
            dataIndex: ["listingElement", "description"],
            width: 200,
            ellipsis: true,
            key: "description",
        },
        {
            title: "Prezzo base/Punti",
            dataIndex: "basePrice",
            width: 100,
            ellipsis: true,
            render: (x) => x.toLocaleString("it-IT"),
            key: "basePrice",
        },
        {
            title: "Applica coefficiente",
            dataIndex: "applyMultiplier",
            width: 50,
            ellipsis: true,
            key: "applyMultiplier",
            render: (x) => (x ? "Si" : "No"),
        },
        {
            title: "Sconto",
            dataIndex: "discount",
            width: 50,
            align: "right",
            ellipsis: true,
            key: "discount",
            render: (x) => (x ?? 0) * 100 + "%",
        },
    ];

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get(`contractListingElement`, { params: { contract: contract.id } })
            .then(({ data }) => setListingElements(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (contract) fetchData();
    }, [contract]);

    const deleteSelectedRecords = (keys) => {
        /*setLoading(true);
        axiosInstance
            .delete(`listing/${id}/element?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(fetchData)
            .finally(() => setLoading(false));*/
    };

    const exportAsCsv = () => {
        const data = listingElements.map((x) => ({
            ID: x.id,
            Categoria: x.listingElement.category.id,
            Voce: x.listingElement.refId,
            Descrizione: x.listingElement.description,
            "PrezzoBase/Punti": x.basePrice,
            ApplicaCoefficiente: x.applyMultiplier ? 1 : 0,
        }));
        exportCsv(data, `listino_${contract.listing.id}`);
    };

    return (
        <div>
            <ImportListingModal
                contract={contract}
                open={importModalOpen}
                onCancel={() => setImportModalOpen(false)}
                onFinish={() => {
                    setImportModalOpen(false);
                    fetchData();
                }}
            />
            <Toolbar
                selectedKeys={selectedRowKeys}
                addAction={
                    user.permissions?.includes("contractListingElement.create") &&
                    (() => navigate("new"))
                }
                editAction={
                    user.permissions?.includes("contractListingElement.update") &&
                    ((k) => navigate(`elements/${k}/edit`))
                }
                deleteAction={
                    user.permissions?.includes("contractListingElement.delete") &&
                    deleteSelectedRecords
                }
                extra={() => (
                    <>
                        <Button onClick={exportAsCsv} type={"text"} icon={<FileExcelOutlined />}>
                            Esporta
                        </Button>
                        <PermissionRequired name={"contractListingElement.update"}>
                            <Button
                                onClick={() => setImportModalOpen(true)}
                                type={"text"}
                                icon={<UploadOutlined />}
                            >
                                Importa
                            </Button>
                        </PermissionRequired>
                    </>
                )}
            />
            <Table
                size={"small"}
                columns={columns}
                loading={loading}
                tableLayout={"fixed"}
                scroll={{ x: columns.reduce((acc, x) => acc + x, 0) }}
                dataSource={listingElements.map((x) => ({ ...x, key: x.id }))}
                pagination={{
                    onChange: () => setSelectedRowKeys([]),
                    showTotal: (x) => `Totale elementi: ${x}`,
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: setSelectedRowKeys,
                }}
            />
        </div>
    );
}
