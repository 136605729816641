import './App.css';
import {Outlet, redirect, useLocation, useNavigate} from "react-router-dom";
import {Breadcrumb, Card, Col, Divider, Layout, Menu, Row, theme} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import logo from './logo.svg'
import UserAvatar from "./common/UserAvatar";
import {createContext, useContext, useEffect, useState} from "react";
import axiosInstance from "./api";

const items = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard',
        permission: 'stats.read'
    },
    {
        key: 'orders',
        label: 'Commesse',
        path: '/orders',
        permission: 'order.read'
    },
    {
        key: 'customersheets',
        label: 'Schede clienti',
        path: '/customersheets',
        permission: 'accountingItem.updatePaidQuantity'
    },
    {
        key: 'functions',
        label: 'Produzione',
        path: '/functions',
        permission: 'accountingItem.read'
    },
    {
        key: 'customers',
        label: 'Clienti',
        path: '/customers',
        permission: 'customer.read'
    },
    {
        key: 'listings',
        label: 'Listini',
        path: '/listings',
        permission: 'listing.read'
    },
    {
        key: 'contacts',
        label: 'Contatti',
        path: '/contacts',
        permission: 'contact.read'
    },
    {
        key: 'teams',
        label: 'Squadre',
        path: '/teams',
        permission: 'team.read'
    },
    {
        key: 'activities',
        label: 'Attività',
        path: '/activities',
        permission: 'activity.read'
    }
];

export const UserContext = createContext({});

function App() {
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const [userInfo, setUserInfo] = useState({})


    useEffect(() => {
        axiosInstance.get('user/me')
            .then(({data}) => {
                setUserInfo(data);
            })
    }, []);

    const navigate = useNavigate()
    const location = useLocation();

    const changePage = ({key}) => navigate(items.filter(x => key === x.key)[0].path)
    const key = items.filter(x => location.pathname.startsWith(x.path))?.[0].key

    const allowedItems = items.filter(x => userInfo?.permissions?.includes(x.permission) ?? false)

    return <UserContext.Provider value={userInfo}>
        <Layout style={{minHeight: '100vh'}}>
            <Header
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 999,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div style={{
                    cursor: "pointer",
                    height: '100%',
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    flexDirection: "row",
                    columnGap: 15
                }} onClick={() => navigate('/orders', {replace: true})}>
                    <img alt="logo" style={{height: 30}} src={logo}/>
                    <h2 style={{margin: 0}}>Reportive</h2>
                </div>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[key]}
                    items={allowedItems}
                    onSelect={changePage}
                    style={{
                        flex: 1,
                        marginLeft: 70,
                        minWidth: 0,
                    }}
                />
                <UserAvatar/>
            </Header>
            <Content
                style={{
                    padding: '0 6px',
                    margin: '16px 0',
                }}
            >
                <Row>
                    <Col
                        xs={{span: 24}}
                        sm={{span: 24}}
                        md={{span: 24}}
                        lg={{span: 24}}
                        xl={{span: 24}}
                        xxl={{span: 24, offset: 0}}
                        style={{
                            padding: 24,
                            minHeight: 380,
                        }}
                    >
                        <Card>
                            <Outlet/>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    </UserContext.Provider>
}

export default App;

