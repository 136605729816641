import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import OrderAccountingSectionItems from "./OrderAccountingSectionItems";
import BackTitle from "../common/BackTitle";
import { emptyOrPlaceholder } from "../util";

export const AccountingContext = createContext();

export default function AccountingDetails() {
    const { orderId, accountingId } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        return axiosInstance
            .get(`/accounting/${accountingId}`)
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Spin spinning={loading}>
                <BackTitle
                    padding={0}
                    title={`Foglio di contabilità: ${emptyOrPlaceholder(data.name)}`}
                />

                <AccountingContext.Provider value={data}>
                    <OrderAccountingSectionItems
                        style={{ marginTop: 10 }}
                        refresh={() => fetchData()}
                        data={data.items ?? []}
                    />
                </AccountingContext.Provider>
            </Spin>
        </div>
    );
}
