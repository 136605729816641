import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActivitiesTable from "../activity/ActivitiesTable";
import NewActivity from "../activity/NewActivity";
import axiosInstance from "../api";
import { UserContext } from "../App";

export default function OrderActivities() {
    const [data, setData] = useState([]);
    const { orderId } = useParams();
    const [newActivityOpen, setNewActivityOpen] = useState(false);

    useEffect(() => {
        axiosInstance
            .get(`activity`, {
                params: {
                    order: orderId,
                    includeCompleted: true,
                },
            })
            .then(({ data }) => setData(data));
    }, []);

    return (
        <>
            <NewActivity
                open={newActivityOpen}
                onCancel={() => setNewActivityOpen(false)}
                onFinish={(activity) => {
                    setData([activity, ...data]);
                    setNewActivityOpen(false)
                }}
            />
            <ActivitiesTable
                data={data}
                hiddenColumns={["order", "customer", "address"]}
                addAction={() => setNewActivityOpen(true)}
            />
        </>
    );
}
