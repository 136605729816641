import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import {
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Select,
    Space,
    Spin,
} from "antd";
import { filterOption, requiredField } from "../util";
import { useNavigate } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import PermissionRequired from "../common/PermissionRequired";
import { UserContext } from "../App";

const options = [
    {
        label: "Non richiesta",
        value: "NOT_REQUIRED",
    },
    {
        label: "In attesa",
        value: "WAITING",
    },
    {
        label: "Ok",
        value: "OK",
    },
];

export default function NewOrder() {
    const [customers, setCustomers] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [types, setTypes] = useState([]);
    const [multipliers, setMultipliers] = useState([]);
    const [selectedContract, setSelectedContract] = useState();

    const [addresses, setAddresses] = useState([]);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [timer, setTimer] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const watch = Form.useWatch([], form);
    const user = useContext(UserContext);

    useEffect(() => {
        axiosInstance.get("customer/compact").then(({ data }) => setCustomers(data));
    }, []);

    useEffect(() => {
        user.permissions?.includes("activity.create") &&
            axiosInstance.get("activityType").then(({ data }) => setTypes(data));
    }, [user.permissions]);

    useEffect(() => {
        if (watch?.customer)
            axiosInstance
                .get(`customer/${watch.customer}/contract/compact`)
                .then(({ data }) => setContracts(data));
    }, [watch?.customer]);

    useEffect(() => {
        if (selectedContract)
            axiosInstance
                .get(
                    `customer/${watch.customer}/contract/${selectedContract.id}/multiplier/compact`,
                )
                .then(({ data }) => setMultipliers(data));
    }, [selectedContract]);

    const handleSearchAddressChange = (text) => {
        if (timer) clearTimeout(timer);
        if (text.length > 3) {
            setLoadingAddresses(true);
            const timeout = setTimeout(() => {
                axios
                    .get("https://geocode.search.hereapi.com/v1/geocode", {
                        params: {
                            q: text,
                            apiKey: process.env.REACT_APP_API_KEY,
                            in: "countryCode:ITA",
                        },
                    })
                    .then(({ data }) => setAddresses(data.items))
                    .finally(() => setLoadingAddresses(false));
            }, 200);
            setTimer(timeout);
        }
    };

    const onFinish = (form) => {
        setLoading(true);
        if (form.activities)
            form.activities = form.activities.map((x) => ({
                ...x,
                municipalityOrdinanceStartDate:
                    x.municipalityOrdinanceInterval &&
                    x.municipalityOrdinanceInterval[0].format("YYYY-MM-DD"),

                municipalityOrdinanceEndDate:
                    x.municipalityOrdinanceInterval &&
                    x.municipalityOrdinanceInterval[1].format("YYYY-MM-DD"),
            }));
        const address = addresses.filter((x) => x.id === form.address)[0];
        form.address = {
            ...address.address,
            lat: address.position.lat,
            lng: address.position.lng,
            id: address.id,
        };
        axiosInstance
            .post("order", form)
            .then(({ data }) => navigate(`/orders/${data.id}`, { replace: true }))
            .then(() => {
                message.success("Commessa creata con successo");
            })
            .finally(() => setLoading(false));
    };
    return (
        <div>
            <BackTitle padding={0} title={"Nuova commessa"} />
            <Form form={form} onFinish={onFinish} layout={"vertical"} style={{ marginTop: 16 }}>
                <Divider orientation="left">Dati generali</Divider>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Space wrap>
                        <Form.Item label={"Titolo"} name={"title"} rules={[requiredField]}>
                            <Input style={{ width: 400 }} />
                        </Form.Item>

                        <Form.Item label={"Indirizzo"} rules={[requiredField]} name={"address"}>
                            <Select
                                showSearch
                                onSearch={handleSearchAddressChange}
                                filterOption={false}
                                style={{ width: 500 }}
                                notFoundContent={
                                    loadingAddresses ? "Caricamento in corso..." : "Nessun elemento"
                                }
                                options={addresses.map((x) => ({
                                    label: x.address.label,
                                    value: x.id,
                                }))}
                            />
                        </Form.Item>
                    </Space>
                    <Space wrap>
                        <Form.Item label={"Cliente"} name={"customer"} rules={[requiredField]}>
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                filterOption={filterOption}
                                options={customers.map((x) => ({ value: x.id, label: x.name }))}
                            />
                        </Form.Item>
                        <Form.Item label={"Contratto"} name={"contract"} rules={[requiredField]}>
                            <Select
                                showSearch
                                style={{ width: 500 }}
                                filterOption={filterOption}
                                onSelect={(id) =>
                                    setSelectedContract(contracts.filter((x) => x.id === id)[0])
                                }
                                disabled={!watch?.customer}
                                options={contracts.map((x) => ({ label: x.name, value: x.id }))}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Coefficiente valorizzazione"}
                            name={"multiplier"}
                            rules={selectedContract?.requireMultiplier ? [requiredField] : []}
                        >
                            <Select
                                style={{ width: 150 }}
                                showSearch
                                filterOption={filterOption}
                                disabled={!selectedContract || !selectedContract?.requireMultiplier}
                                options={multipliers.map((x) => ({ label: x.name, value: x.id }))}
                            />
                        </Form.Item>
                    </Space>
                </div>
                <PermissionRequired name={"activity.create"}>
                    <Divider orientation="left">Attività</Divider>
                    <Form.List name="activities">
                        {(fields, { add, remove }) => (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {fields.map((field) => (
                                    <Space key={field.key} wrap>
                                        <Form.Item
                                            label="Categoria"
                                            name={[field.name, "activityType"]}
                                            rules={[requiredField]}
                                        >
                                            <Select
                                                style={{ width: 200 }}
                                                options={types.map((x) => ({
                                                    label: x.name,
                                                    value: x.id,
                                                }))}
                                                filterOption={[filterOption]}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Quantità da eseguire"
                                            rules={[requiredField]}
                                            name={[field.name, "toDoQuantity"]}
                                        >
                                            <InputNumber
                                                style={{ width: 150 }}
                                                suffix={
                                                    types.filter(
                                                        (x) =>
                                                            x.id ===
                                                            watch.activities[field.name]
                                                                ?.activityType,
                                                    )?.[0]?.measureUnit ?? ""
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Autorizzazione"
                                            name={[field.name, "authorizationState"]}
                                        >
                                            <Select style={{ width: 150 }} options={options} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Ordinanza"
                                            rules={
                                                watch.activities[field.name]?.authorizationState ===
                                                    "OK" && [requiredField]
                                            }
                                            name={[field.name, "municipalityOrdinanceState"]}
                                        >
                                            <Select
                                                disabled={
                                                    watch.activities[field.name]
                                                        ?.authorizationState !== "OK"
                                                }
                                                style={{ width: 150 }}
                                                options={options}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Date ordinanza"
                                            name={[field.name, "municipalityOrdinanceInterval"]}
                                            rules={
                                                watch.activities[field.name]
                                                    ?.municipalityOrdinanceState &&
                                                watch.activities[field.name]
                                                    ?.municipalityOrdinanceState !==
                                                    "NOT_REQUIRED" && [requiredField]
                                            }
                                        >
                                            <DatePicker.RangePicker
                                                disabled={
                                                    !watch.activities[field.name]
                                                        ?.municipalityOrdinanceState ||
                                                    watch.activities[field.name]
                                                        ?.municipalityOrdinanceState ===
                                                        "NOT_REQUIRED"
                                                }
                                                style={{ width: 300 }}
                                                format="DD/MM/YYYY"
                                            />
                                        </Form.Item>
                                        <Form.Item label="Note" name={[field.name, "notes"]}>
                                            <TextArea />
                                        </Form.Item>
                                        <Form.Item label=" ">
                                            <Button
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => remove(field.name)}
                                            >
                                                Rimuovi
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                ))}
                                <Button style={{ width: 150 }} onClick={() => add()}>
                                    Aggiungi
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </PermissionRequired>
                <Form.Item style={{ textAlign: "end" }}>
                    <Button htmlType={"submit"} loading={loading} type={"primary"}>
                        Salva
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
