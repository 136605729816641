import { GlobalOutlined, TableOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Form, Row, Segmented, Select, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../api";
import BackTitle from "../common/BackTitle";
import { filterOption } from "../util";
import ActivitiesMap from "./ActivitiesMap";
import dayjs from "dayjs";
import ActivitiesTable from "./ActivitiesTable";

export default function Activities() {
    const [data, setData] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();

    useState(() => {
        axiosInstance
            .get("customer/compact")
            .then(({ data }) => setCustomers(data.map((x) => ({ label: x.name, value: x.id }))));

        axiosInstance
            .get("activityType")
            .then(({ data }) =>
                setActivityTypes(data.map((x) => ({ label: x.name, value: x.id }))),
            );
    }, []);

    const onFinish = (form) => {
        if (form.date) {
            form.startDate = form.date[0].format("YYYY-MM-DD");
            form.endDate = form.date[1].format("YYYY-MM-DD");
            delete form.date;
        }
        const view = searchParams.get("view");
        [...searchParams.keys()].forEach((x) => searchParams.delete(x));
        view && searchParams.set("view", view);
        Object.entries(form)
            .filter(([_, v]) => !!v && ("" + v).length > 0)
            .forEach(([k, v]) => searchParams.set(k, v));
        setSearchParams(searchParams);
    };

    useEffect(() => {
        setLoading(true);
        const params = {
            ...Array.from(searchParams.entries() ?? []).reduce(
                (acc, curr) => ({ ...acc, [curr[0]]: curr[1] }),
                {},
            ),
            type: (searchParams.get("type") ?? null)?.split(","),
        };
        axiosInstance
            .get("activity", {
                params,
                paramsSerializer: {
                    indexes: null,
                },
            })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));

        form.setFieldsValue({
            customer: !!searchParams.get("customer")
                ? parseInt(searchParams.get("customer"))
                : null,
            type: searchParams.get("type")?.split(","),
            date:
                !!searchParams.get("startDate") && !!searchParams.get("endDate")
                    ? [dayjs(searchParams.get("startDate")), dayjs(searchParams.get("endDate"))]
                    : null,
        });
    }, [searchParams]);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <BackTitle backEnabled={false} title="Attività" padding={0} />

            <div>
                <Form layout="vertical" onFinish={onFinish} form={form}>
                    <Row>
                        <Col flex={1}>
                            <Space wrap>
                                <Form.Item label="Cliente" name="customer">
                                    <Select
                                        style={{ width: 150 }}
                                        showSearch
                                        options={customers}
                                        filterOption={[filterOption]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Categoria attività" name="type">
                                    <Select
                                        mode="multiple"
                                        style={{ width: 200 }}
                                        showSearch
                                        options={activityTypes}
                                        filterOption={[filterOption]}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item label="Intervallo di date" name="date">
                                    <DatePicker.RangePicker format="DD/MM/YYYY" />
                                </Form.Item>
                                <Form.Item
                                    label="Altro"
                                    name={"includeCompleted"}
                                    valuePropName="checked"
                                >
                                    <Checkbox>Includi completati</Checkbox>
                                </Form.Item>
                            </Space>
                        </Col>
                        <Col flex={"auto"} style={{ textAlign: "end" }}>
                            <Space wrap>
                                <Form.Item label=" ">
                                    <Button
                                        onClick={() => {
                                            const view = searchParams.get("view");
                                            [...searchParams.keys()].forEach((x) =>
                                                searchParams.delete(x),
                                            );
                                            view && searchParams.set("view", view);
                                            setSearchParams(searchParams);
                                            form.resetFields();
                                        }}
                                    >
                                        Reset filtri
                                    </Button>
                                </Form.Item>
                                <Form.Item label=" ">
                                    <Button htmlType="submit" type="primary">
                                        Applica filtri
                                    </Button>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Segmented
                    value={searchParams.get("view") ?? "table"}
                    onChange={(value) => {
                        searchParams.set("view", value);
                        setSearchParams(searchParams);
                    }}
                    options={[
                        {
                            label: (
                                <span style={{ display: "inline-block", width: 100 }}>
                                    <TableOutlined /> Tabella
                                </span>
                            ),
                            value: "table",
                        },
                        {
                            label: (
                                <span style={{ display: "inline-block", width: 100 }}>
                                    <GlobalOutlined /> Mappa
                                </span>
                            ),
                            value: "map",
                        },
                    ]}
                />
            </div>
            <Spin spinning={loading}>
                {searchParams.get("view") === "map" ? (
                    <ActivitiesMap activities={data} />
                ) : (
                    <ActivitiesTable data={data} />
                )}
            </Spin>
        </div>
    );
}
