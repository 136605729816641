import { Button, Form, message, Modal, Select } from "antd";
import { filterOption, requiredField } from "../util";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import Dragger from "antd/es/upload/Dragger";
import TextArea from "antd/lib/input/TextArea";

export default function SelectEmailRecipients({ customerId, onCancel, open, onFinish }) {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (customerId) {
            axiosInstance
                .get(`contact?customerId=${customerId}&pageSize=999999`)
                .then(({ data }) =>
                    setContacts(data.data.map((x) => ({ value: x.id, label: x.fullName }))),
                );
        }
    }, [customerId]);

    useEffect(() => {
        if (open) form.resetFields();
    }, [open]);

    return (
        <Modal
            title="Componi email"
            okButtonProps={{ loading: loading, form: "composeEmail", htmlType: "submit" }}
            onCancel={onCancel}
            open={open}
        >
            <Form onFinish={onFinish} layout={"vertical"} id="composeEmail" form={form}>
                <Form.Item label={"A"} name={"to"} rules={[requiredField]}>
                    <Select showSearch filterOption={filterOption} options={contacts} />
                </Form.Item>

                <Form.Item label={"CC"} name={"ccs"}>
                    <Select mode={"multiple"} filterOption={filterOption} options={contacts} />
                </Form.Item>
                <Form.Item label="Allegati" name={"attachments"} valuePropName={"file"}>
                    <Dragger beforeUpload={() => false} multiple>
                        Trascina qui gli allegati oppure clicca per selezionarli
                    </Dragger>
                </Form.Item>
                <Form.Item label="Messaggio (facoltativo)" name={"notes"}>
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
}
