import { DatePicker, Form, InputNumber, Modal, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import dayjs from "dayjs";

const options = [
    {
        label: "Non richiesta",
        value: "NOT_REQUIRED",
    },
    {
        label: "In attesa",
        value: "WAITING",
    },
    {
        label: "Ok",
        value: "OK",
    },
];

export default function EditActivity({ activityId, onCancel, onFinish }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([]);
    const authorizationStateWatch = Form.useWatch("authorizationState", form);
    const municipalityOrdinanceStateWatch = Form.useWatch("municipalityOrdinanceState", form);

    useEffect(() => {
        axiosInstance.get("activityType").then(({ data }) => setTypes(data));
    }, []);

    useEffect(() => {
        if (activityId) {
            form.resetFields();
            setLoading(true);
            axiosInstance
                .get(`activity/${activityId}`)
                .then(({ data }) =>
                    form.setFieldsValue({
                        ...data,
                        activityType: data.type.id,
                        municipalityOrdinanceInterval: data.municipalityOrdinanceStartDate && [
                            dayjs(data.municipalityOrdinanceStartDate),
                            dayjs(data.municipalityOrdinanceEndDate),
                        ],
                    }),
                )
                .finally(() => setLoading(false));
        }
    }, [activityId]);

    const onFormFinish = (data) => {
        setLoading(true);
        data.municipalityOrdinanceStartDate =
            data.municipalityOrdinanceInterval &&
            data.municipalityOrdinanceInterval[0].format("YYYY-MM-DD");
        data.municipalityOrdinanceEndDate =
            data.municipalityOrdinanceInterval &&
            data.municipalityOrdinanceInterval[1].format("YYYY-MM-DD");
        axiosInstance
            .put(`activity/${activityId}`, { ...data })
            .then(({ data }) => onFinish?.(data))
            .finally(() => setLoading(false));
    };
    return (
        <Modal
            open={!!activityId}
            title="Modifica attività"
            okButtonProps={{ htmlType: "submit", form: "editActivityForm" }}
            {...{ onCancel }}
        >
            <Spin spinning={loading}>
                <Form layout="vertical" form={form} id="editActivityForm" onFinish={onFormFinish}>
                    <Form.Item label="Categoria" name={"activityType"} rules={[requiredField]}>
                        <Select
                            options={types.map((x) => ({
                                label: x.name,
                                value: x.id,
                            }))}
                            filterOption={[filterOption]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Autorizzazione"
                        name={"authorizationState"}
                        rules={[requiredField]}
                    >
                        <Select options={options} />
                    </Form.Item>
                    <Form.Item
                        label="Ordinanza"
                        name={"municipalityOrdinanceState"}
                        rules={authorizationStateWatch === "OK" && [requiredField]}
                    >
                        <Select disabled={authorizationStateWatch !== "OK"} options={options} />
                    </Form.Item>
                    <Form.Item
                        label="Date ordinanza"
                        name={"municipalityOrdinanceInterval"}
                        rules={
                            municipalityOrdinanceStateWatch &&
                            municipalityOrdinanceStateWatch !== "NOT_REQUIRED" && [requiredField]
                        }
                    >
                        <DatePicker.RangePicker
                            disabled={
                                !municipalityOrdinanceStateWatch ||
                                municipalityOrdinanceStateWatch === "NOT_REQUIRED"
                            }
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item label="Note" name={"notes"}>
                        <TextArea />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}
