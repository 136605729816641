import { DatePicker, Form, InputNumber, Modal, Select, Spin, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";

const options = [
    {
        label: "Non richiesta",
        value: "NOT_REQUIRED",
    },
    {
        label: "In attesa",
        value: "WAITING",
    },
    {
        label: "Ok",
        value: "OK",
    },
];

export default function NewActivity({ open, onCancel, onFinish }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([]);
    const authorizationStateWatch = Form.useWatch("authorizationState", form);
    const municipalityOrdinanceStateWatch = Form.useWatch("municipalityOrdinanceState", form);
    const activityTypeWatch = Form.useWatch("activityType", form);
    const { orderId } = useParams();

    useEffect(() => {
        axiosInstance.get("activityType").then(({ data }) => setTypes(data));
    }, []);

    const onFormFinish = (data) => {
        data.municipalityOrdinanceStartDate =
            data.municipalityOrdinanceInterval &&
            data.municipalityOrdinanceInterval[0].format("YYYY-MM-DD");

        data.municipalityOrdinanceEndDate =
            data.municipalityOrdinanceInterval &&
            data.municipalityOrdinanceInterval[1].format("YYYY-MM-DD");
        setLoading(true);
        axiosInstance
            .post("activity", { ...data, order: parseInt(orderId) })
            .then(({ data }) => onFinish?.(data))
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title="Nuova attività"
            okButtonProps={{ htmlType: "submit", form: "newActivityForm" }}
            {...{ open, onCancel }}
        >
            <Spin spinning={loading}>
                <Form layout="vertical" form={form} id="newActivityForm" onFinish={onFormFinish}>
                    <Form.Item label="Categoria" name={"activityType"} rules={[requiredField]}>
                        <Select
                            options={types.map((x) => ({
                                label: x.name,
                                value: x.id,
                            }))}
                            showSearch
                            filterOption={[filterOption]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Autorizzazione"
                        name={"authorizationState"}
                        rules={[requiredField]}
                    >
                        <Select options={options} />
                    </Form.Item>
                    <Form.Item
                        label="Ordinanza"
                        name={"municipalityOrdinanceState"}
                        rules={authorizationStateWatch === "OK" && [requiredField]}
                    >
                        <Select disabled={authorizationStateWatch !== "OK"} options={options} />
                    </Form.Item>
                    <Form.Item
                        label="Date ordinanza"
                        name={"municipalityOrdinanceInterval"}
                        rules={
                            municipalityOrdinanceStateWatch &&
                            municipalityOrdinanceStateWatch !== "NOT_REQUIRED" && [requiredField]
                        }
                    >
                        <DatePicker.RangePicker
                            disabled={
                                !municipalityOrdinanceStateWatch ||
                                municipalityOrdinanceStateWatch === "NOT_REQUIRED"
                            }
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item label="Note" name={"notes"}>
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Quantità da eseguire"
                        name={"toDoQuantity"}
                        rules={[requiredField]}
                    >
                        <InputNumber
                            style={{ width: 150 }}
                            suffix={
                                types.filter((x) => x.id === activityTypeWatch)?.[0]?.measureUnit ??
                                ""
                            }
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}
