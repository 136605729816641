import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Popconfirm, Row, Space, Spin, Table } from "antd";
import axiosInstance from "../api";
import NewListingElement from "./NewListingElement";
import {
    DeleteOutlined,
    EditOutlined,
    FileExcelOutlined,
    FileExcelTwoTone,
    UploadOutlined,
} from "@ant-design/icons";
import EditListingElement from "./EditListingElement";
import Toolbar from "../common/Toolbar";
import BackTitle from "../common/BackTitle";
import { exportCsv } from "../util";
import { UserContext } from "../App";
import PermissionRequired from "../common/PermissionRequired";

export default function ListingDetails() {
    const { id } = useParams();
    const [listing, setListing] = useState({});
    const [loading, setLoading] = useState(false);
    const [elementsLoading, setElementsLoading] = useState(false);
    const [listingElements, setListingElements] = useState([]);
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const user = useContext(UserContext);

    const columns = [
        {
            title: "Categoria",
            dataIndex: "category",
            width: 100,
            ellipsis: true,
            key: "category",
            render: (x) => x.name,
        },
        {
            title: "Etichetta",
            dataIndex: "label",
            width: 100,
            ellipsis: true,
            key: "label",
        },
        {
            title: "Voce",
            dataIndex: "refId",
            width: 100,
            ellipsis: true,
            key: "refId",
        },
        {
            title: "Descrizione",
            dataIndex: "description",
            width: 200,
            ellipsis: true,
            key: "description",
        },
        {
            title: "Prezzo base/Punti",
            dataIndex: "basePrice",
            width: 100,
            ellipsis: true,
            render: (x) => x.toLocaleString("it-IT"),
            key: "basePrice",
        },
        {
            title: "Applica coefficiente",
            dataIndex: "applyMultiplier",
            width: 50,
            ellipsis: true,
            key: "applyMultiplier",
            render: (x) => (x ? "Si" : "No"),
        },
    ];

    const fetchData = () => {
        setLoading(true);
        setElementsLoading(true);
        axiosInstance
            .get(`listing/${id}`)
            .then(({ data }) => setListing(data))
            .finally(() => setLoading(false));

        axiosInstance
            .get(`listing/${id}/element`)
            .then(({ data }) => setListingElements(data.sort((a, b) => a.id - b.id)))
            .finally(() => setElementsLoading(false));
    };
    useEffect(() => {
        fetchData();
    }, []);

    const deleteSelectedRecords = (keys) => {
        setLoading(true);
        axiosInstance
            .delete(`listing/${id}/element?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(fetchData)
            .finally(() => setLoading(false));
    };

    const exportAsCsv = () => {
        const data = listingElements.map((x) => ({
            ID: x.id,
            Categoria: x.category.id,
            Voce: x.refId,
            Descrizione: x.description,
            "PrezzoBase/Punti": x.basePrice,
            ApplicaCoefficiente: x.applyMultiplier ? 1 : 0,
        }));
        exportCsv(data, `listino_${listing.id}`);
    };

    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <Row>
                    <Col span={12}>
                        <BackTitle padding={0} title={`Dettagli listino: ${listing.name}`} />
                    </Col>
                    <Col span={12}>
                        <PermissionRequired name={"listing.update"}>
                            <Space style={{ width: "100%", height: "100%", justifyContent: "end" }}>
                                <Button
                                    type={"link"}
                                    onClick={() => navigate("edit")}
                                    icon={<EditOutlined />}
                                >
                                    Modifica
                                </Button>
                            </Space>
                        </PermissionRequired>
                    </Col>
                </Row>
                <Toolbar
                    selectedKeys={selectedRowKeys}
                    addAction={
                        user.permissions?.includes("listing.create") && (() => navigate("new"))
                    }
                    editAction={
                        user.permissions?.includes("listing.update") &&
                        ((k) => navigate(`elements/${k}/edit`))
                    }
                    deleteAction={
                        user.permissions?.includes("listing.delete") && deleteSelectedRecords
                    }
                    extra={() => (
                        <>
                            <Button
                                onClick={exportAsCsv}
                                type={"text"}
                                icon={<FileExcelOutlined />}
                            >
                                Esporta
                            </Button>
                            <PermissionRequired name={"listing.create"}>
                                <Button
                                    onClick={() => navigate("import")}
                                    type={"text"}
                                    icon={<UploadOutlined />}
                                >
                                    Importa
                                </Button>
                            </PermissionRequired>
                        </>
                    )}
                />
                <Table
                    size={"small"}
                    columns={columns}
                    loading={elementsLoading}
                    tableLayout={"fixed"}
                    scroll={{ x: columns.reduce((acc, x) => acc + x, 0) }}
                    dataSource={listingElements.map((x) => ({ ...x, key: x.id }))}
                    pagination={{
                        onChange: () => setSelectedRowKeys([]),
                        showTotal: (x) => `Totale elementi: ${x}`,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                setSelectedRowKeys([record.key]);
                            }, // click row
                        };
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                />
            </div>
        </Spin>
    );
}
